<template>
    <div>        

        <!-- TopNav default -->        
        <v-app-bar
          dense
          fixed
          class="topnav"
          v-if="template=='default'"
          >

          <v-btn class="logo" :to="{ name: 'Home'}" text style="margin-right:20px;">
            <img src="/graf/TestbedLogo_Icon_klein.svg" width="40">
          </v-btn>

          <v-toolbar-title>NAO Leseroboter</v-toolbar-title> 

          <v-spacer></v-spacer>
        
          <!--
          <router-link :to="{ name: 'Home'} ">
              <v-icon>mdi-home</v-icon>
          </router-link>
          -->
                  
          <v-spacer></v-spacer>

          <!--
          <div v-if="$route.path.indexOf('/t/') !== -1" style="display:flex">
            <div style="display:inline-flex">
              <h4 style="padding-top:11px;font-size:1rem">{{ node.name }}</h4>
            </div>
            <div style="display:inline-flex">
              <UsecaseExit :url="'xxx/xxx'"></UsecaseExit>
            </div>
          </div>

          <div v-else>          
            
            <div v-if="host.indexOf('testbed.') === -1">

                <router-link :to="{ name: 'Suche'} " class="mt-2 mx-6 mainitem">                
                    <v-icon 
                      size="19"
                      style="margin-top:-2px"
                      class="mr-1"
                      >
                    mdi-magnify
                    </v-icon>
                    Suche                
                </router-link>

                <router-link :to="{ name: 'Virtuell'} " :class=" panoActive ? 'active mx-6 mainitem' : 'mx-6 mainitem' ">
                    <v-icon 
                      size="19"
                      style="margin-top:-2px"                  
                      class="mr-1"
                      >
                      mdi-panorama-horizontal
                    </v-icon>
                    Virtuelle Touren
                </router-link>

                <router-link v-if="user.name" :to="{ name: 'Admin'} " class="ml-4 mr-4">
                    <v-icon>mdi-cog</v-icon>
                </router-link>

                <v-menu          
                  offset-y
                  offset-x
                  v-if="user.name"            
                >
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" depressed small class="ml-2">
                      <v-icon>mdi-account</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <router-link :to="{ name: 'Login' }">
                        <v-list-item-title>Profil</v-list-item-title>
                      </router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link :to="{ name: 'Login' }">
                        <v-list-item-title>Logout</v-list-item-title>
                      </router-link>
                    </v-list-item>
                  </v-list>
                </v-menu>          

                <router-link :to="{ name: 'Login'} " class="mt-2 mx-6" v-else>                
                    <v-icon size="18"
                    class="lockbutton"
                    style="margin-top:-2px"
                    >mdi-lock</v-icon>
                </router-link>

                <v-btn icon class="menu" @click="toggleMenu">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>

            </div>

          </div>
          -->

        </v-app-bar>

    </div>
</template>

<script>
//import { log } from 'three';
import { eventBus } from '../main.js';
//import UsecaseExit from './controls/UsecaseExit.vue';

export default {
  props: {
    drawer: Object,
    template: String,
    controls: Object
  },
  components: {
  //  UsecaseExit
  },
  data(){
    return {
      host: window.location.host,
      items: [
        { title: 'Stage', icon: 'mdi-home-city', link: 'Stage' },
        { title: 'Admin', icon: 'mdi-account-tie', link: 'Admin' },
        { title: 'Users', icon: 'mdi-account-tie', link: 'List' },
        { title: 'Categories', icon: 'mdi-account-tie', link: 'Layout' }
      ],
      testfields: [
        { title: 'NAO-Leseroboter', icon: '', link: 'Stage' },
        { title: 'NLP', icon: '', link: 'NLP' },
        { title: 'Digitale Bibliothek', icon: '', link: 'SpaceBib' },

      ],
      admindrawer: null,
      //user: this.$store.getters.userdata      
    }
  },
  methods:{
      changeDrawer() {
          this.drawer.visible = !this.drawer.visible;
      },
      fetchTemplate() {
          //console.log("Fetch Template in TopNav:");
          //console.log(this.template);
      },
      toggleMenu() {
          this.controls.visible = !this.controls.visible;
          //console.log("topnav");
          eventBus.$emit('controls-visible', this.controls.visible);          
      }      
  },
  computed:{
      user () {
        return this.$store.getters.userdata
      },
      node: {
        get() {
          return this.$store.getters.node
        },
        set(val) {
          return val
        }        
      },
      panoActive () {
        return this.$route.path.indexOf('/panorama') !== -1 ||
          this.$route.path.indexOf('/pn/') !== -1
      }
  },
  watch: {
      user: function (values) {          
          this.node = values;          
      }
  },
  mounted() {
    this.fetchTemplate();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.logo {
  margin-right:2px;
}
.logo.v-btn--active {
  color:#ffffff;
}
.topnav {
  background-color:#fff;  
  color:#005ca9 !important;
  height:60px;
  padding-left:6px;
  -webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75) !important;
  box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75) !important;
}
.topnav a:hover {
  text-decoration:none;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color:#fff;
}
.v-icon,
.v-btn .v-icon {
  color:#0076bc;
}

.v-icon.lockbutton {
  color:#999;
}

a.mainitem:hover,
a.mainitem.router-link-active,
a.active,
a.mainitem:hover .v-icon,
a.mainitem.router-link-active .v-icon,
a.active .v-icon {  
  color:orange;
}


/* icampus template */
.titleplus {
  color:#0d60b2;
  padding:4px 10px;
  font-weight:bold !important;
  font-size:1rem;
  background-image: linear-gradient(to right, #f49e30, #ed7724);
}
.topnav.icampus {
  background-color:transparent;
  color:#ffffff !important;
  background-image: linear-gradient(to bottom, #042f7e, #0d64ac);
}
.icampus .v-icon,
.icampus .v-btn .v-icon {
  color:#ffffff !important;
}

</style>

<style>
.topnav .v-toolbar__content {
  height:51px !important;
}
</style>