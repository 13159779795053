// components/monitor/InfoBox.vue
<template>

  <v-card class="showbox" >    
    <v-tooltip left color="#333333">
    <template v-slot:activator="{ on, attrs }">
    <v-icon
      color="#ffffff"
      size="20"
      class="my-2 mr-2"
      v-on="on"
      v-bind="attrs"
    >mdi-account-voice</v-icon>
    </template>
    <span>NAO spricht</span>
    </v-tooltip>
    <div v-html="answer"></div>     
  </v-card>

</template>

<script>
export default {
  name: 'ShowBox',
  props: {
      answer: {
          type: String,
          default: '+++'
      }
  },
  data () {
    return {
      isOpen: true,
    }
  },
  methods:{
    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style>
.showbox {
  margin:10px 0 20px;
  position:relative;
  padding:2px 12px 12px;
  width:100%;
  min-height:70px;
  color:#ffffff !important;
  background:#333 !important;
  font-size:1.2rem;
}

</style>
