// components/monitor/HelpBox.vue
<template>

  <v-card class="helpbox" >    
    <v-tooltip left color="#333333">
    <template v-slot:activator="{ on, attrs }">
    <v-icon
      color="#666"
      size="20"
      class="my-1 mx-0"
      v-on="on"
      v-bind="attrs"      
    >mdi-help-circle-outline</v-icon>
    </template>
    <span>Hilfe und Tipps</span>
    </v-tooltip>
    <div v-html="help" ></div>     
  </v-card>

</template>

<script>
export default {
  name: 'HelpBox',
  props: {
      help: {
          type: String,
          default: ''
      }
  },
  data () {
    return {
      isOpen: true,
    }
  },
  methods:{
    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style>
.helpbox {
  margin:10px 0 20px;
  position:relative;
  padding:2px 12px 6px;
  width:100%;  
  color:#000000 !important;
  background:#ffff99 !important;
  font-size:1rem;
}

</style>
