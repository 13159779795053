// components/monitor/InfoBox.vue
<template>
  <div>

  <v-card class="talkbox mt-4 mb-4" >
    <v-tooltip left color="#333333">
    <template v-slot:activator="{ on, attrs }">
    <v-icon
      color="#ffffff"
      size="20"
      class="my-0 mr-2"
      v-on="on"
      v-bind="attrs"
    >mdi-flag-variant</v-icon>      
    </template>
    <span>Aktueller Prozessschritt</span>
    </v-tooltip>
    <span v-html="answer" v-if="answer != ''"></span>      
  </v-card>
  </div>

</template>

<script>
export default {
  name: 'TalkBox',
  props: {
      answer: {
          type: String,
          default: '+++'
      }
  },
  data () {
    return {
      isOpen: true,
    }
  },
  methods:{
    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style>
.talkbox {
  position:relative;
  padding:12px;
  width:auto;
  font-size:1rem;
  min-height:3rem;
  color:#ffffff !important;
  background-color:transparent !important;
  border:1px solid rgba(255,255,255,0.4);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.talkbox label {
  display:block;
  float:left;
  margin-right:20px;
}

</style>
